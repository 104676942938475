(function($) {
    
    $.getJSON(site_url + '/wp-json/wp/v2/portfolio?per_page=100', function(response){
        var get_json = response;                
    })
    .error(function() { $('#map-error').append('<div class="alert alert-warning text-center">Map Error</div>'); })
    .success(function(response) { 
        
        var screen = $(window).width();
        if (screen > 992) {
            // Set Markers
            var NYCmarkers = [];
            $.each(response, function(i, field){
                
                if(field.acf.historical_property == 'no') {
                    if(field.acf.state == 'NY') {
                        var prop_img = '<div class="popup-info"><div id="nyc-pop-scroll"></div><div class="prop-img-wrap"><img src="' + field.acf.property_image_url + '" alt="' + field.title.rendered + '" class="prop-img img-responsive"></div>';
                        var title = '<div class="prop-title">' + field.title.rendered + '</div>';
                        var street = '<div class="street">' + field.acf.street_address + '</div>';
                        var city_state = '<div class="city-state">' + field.acf.city + ', ' + field.acf.state + '</div>';
                        var total_sf = '<div class="total-sf">' + field.acf.gross_living_area + ' Total SF</div>';

                        var vacant_sf_total = 0;
                        $.each(field.acf.square_footage, function() {
                            vacant_sf_total += parseFloat(this.unit_square_footage);
                        });
            
                        var vacant_sf = '<div class="vacant-sf">' + vacant_sf_total + ' Vacant SF</div>';
                        var prop_type = '<div class="prop-type">' + field.acf.property_type + ' Space</div>';
                        var explore = '<a href="' + field.acf.explore_the_area + '" class="explore">Explore the Area</a>';
                        var download = '<a href="' + field.acf.download_brochure + '" class="download">Download Brochure</a>';
                        var link = '<a href="' + field.link + '" class=""><div class="popover-arrow"></div></a>';
                    
                        var map = '</div><div class="popup-map"><iframe src="https://maps.google.com/maps?q=' + field.acf.latitude + ',' + field.acf.longitude + '&hl=es;z=14&output=embed"></iframe></div>';
                    
                        NYCmarkers[i] = {src:  templateDir + '/dist/images/markers/NYCmarker.png', id: i, geoCoords: [field.acf.latitude,field.acf.longitude], popover: prop_img + title + street + city_state + total_sf + vacant_sf + prop_type + explore + download + link + map};
                    }
                }
            });
        } else {
        
            // Use a link on mobile instead of the popover
            var NYCmarkers = [];
            $.each(response, function(i, field){
            
                if(field.acf.historical_property == 'no') {
                    if(field.acf.state == 'NY') {
                        NYCmarkers[i] = {src:  templateDir + '/dist/images/markers/NYCmarker.png', id: i, geoCoords: [field.acf.latitude,field.acf.longitude], href: field.link}; 
                    }
                }
            });
        }
        
        // Create SVG Map
        $('#newyork-mapsvg').mapSvg({
            source: templateDir + '/map/newyorkcity2.svg',
            popovers: {mode: "on"},
            markers: NYCmarkers, // Uncomment this to display markers on Manhattan map
            onClick: function(){
                        $('html,body').animate({
                            scrollTop: $('#nyc-pop-scroll').offset().top - 50
                        }, 500);
                    } 
        });
        
        var screen = $(window).width();
        if (screen > 992) {
            // Set Markers
            var markers = [];
            $.each(response, function(i, field){
                
                if(field.acf.historical_property == 'no') {
                    var prop_img = '<div id="pop-scroll"></div><div class="prop-img-wrap"><img src="' + field.acf.property_image_url + '" alt="' + field.title.rendered + '" class="prop-img img-responsive"></div>';
                    var title = '<div class="prop-title">' + field.title.rendered + '</div>';
                    var street = '<div class="street">' + field.acf.street_address + '</div>';
                    var city_state = '<div class="city-state">' + field.acf.city + ', ' + field.acf.state + '</div>';
                    var total_sf = '<div class="total-sf">' + field.acf.gross_living_area + ' Total SF</div>';

                    var vacant_sf_total = 0;
                    $.each(field.acf.square_footage, function() {
                        vacant_sf_total += parseFloat(this.unit_square_footage);
                    });
            
                    var vacant_sf = '<div class="vacant-sf">' + vacant_sf_total + ' Vacant SF</div>';
                    var prop_type = '<div class="prop-type">' + field.acf.property_type + ' Space</div>';
                    var explore = '<a href="' + field.acf.explore_the_area + '" class="explore">Explore the Area</a>';
                    var download = '<a href="' + field.acf.download_brochure + '" class="download">Download Brochure</a>';
                    var link = '<a href="' + field.link + '" class=""><div class="popover-arrow"></div></a>';
                    
                    markers[i] = {src:  templateDir + '/dist/images/markers/marker.png', id: i, geoCoords: [field.acf.latitude,field.acf.longitude], popover: prop_img + title + street + city_state + total_sf + vacant_sf + prop_type + explore + download + link};
            
                }
            });
        } else {
        
            // Use a link on mobile instead of the popover
            var markers = [];
            $.each(response, function(i, field){
            
                if(field.acf.historical_property == 'no') {
                    markers[i] = {src:  templateDir + '/dist/images/markers/marker.png', id: i, geoCoords: [field.acf.latitude,field.acf.longitude], href: field.link};
                }
            });
        }
        
        // Create SVG Map
        $('#usa-mapsvg').mapSvg({
            source: templateDir + '/map/map.svg',
            popovers: {mode: "on"},
            markers: markers,
            onClick: function(){
                        $('html,body').animate({
                            scrollTop: $('#pop-scroll').offset().top - 50
                        }, 500);
                    } 
        });
    });

})(jQuery); 

                